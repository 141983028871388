import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import config from "../config/"

const { navLinks } = config

const StyledNav = styled.nav`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 31.25rem;
    background: ${({ theme }) => theme.colors.background};
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .nav-link {
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    margin: 0 0 0 3rem;
    padding: 0;
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.colors.primary};
      width: 0%;
      bottom: -0.125rem;
    }
    &:hover::before {
      width: 100%;
    }
  }
  .cta-btn {
    width: auto;
    height: auto;
    font-weight: 700;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background};
    transition: 200ms ease-out;
    font-size: 1.125rem;
    padding: 0.5rem 1.5rem;
    margin: 0 0 0 3rem;
    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      color: #ffffff;
    }
  }
`

const Navbar = () => {
  const { menu, button } = navLinks
  return (
    <StyledNav>
      {menu.map(({ name, url }, key) => {
        if (url === "/resume") {
          return (
            <a className="nav-link" key={key} href={url} target="_blank">
              {name}
            </a>
          );
        } else {
          return (
            <Link className="nav-link" key={key} to={url}>
              {name}
            </Link>
          )
        }
      })}
      <a className="cta-btn" href={button.url}>
        {button.name}
      </a>
    </StyledNav>
  )
}

export default Navbar
