import React from "react"
import styled from "styled-components"
import { socialMedia } from "../config"

import ContentWrapper from "../styles/ContentWrapper"
import Icon from "./icons"

const StyledFooter = styled.footer`
  width: 100%;
  height: ${({ theme }) => theme.footerHeight};
  background: ${({ theme }) => theme.colors.primary};
  margin-top: 10rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    .copyright {
      font-size: 1rem;
      color: #fff;
      margin-bottom: 0.5rem;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    .footer-links {
      display: flex;
      flex-direction: row;
      margin-top: 0.25rem;
      color: white;

      .footer-social-link {
        display: flex;
        align-items: center;
        text-decoration: underline;
      }

      .footer-social-link:not(:last-child) {
          margin-right: 2rem;
      }

      .email-social-link {
        display: flex;
        flex-direction: column;
        padding-top: 0.175rem;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          padding-top: 0;
          justify-content: center;
        }
      }
    }
  }
`

const Footer = () => (
  <StyledFooter>
    <StyledContentWrapper>
      <span className="copyright">© 2023 Kevin Tam</span>
      <nav className="footer-links">
        {socialMedia.map(({ name, url }) => (
          <a className="footer-social-link" href={url} key={name} target="_blank" rel="nofollow noopener noreferrer">
            <span>{name}</span>
          </a>
        ))}
      </nav>
    </StyledContentWrapper>
  </StyledFooter>
)

export default Footer
