module.exports = {
  author: "@KevinTamCS",
  siteTitle: "Kevin Tam - Software Engineer",
  siteShortTitle: "Kevin Tam", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "Hello! I'm Kevin, an aspiring Software Engineer.",
  siteUrl: "https://kevintamcs.com",
  siteLanguage: "en_US",
  siteIcon: "src/content/favicon.png", // Relative to gatsby-config file

  splashScreen: false, // Set this to true if you want to use the splash screen

  socialMedia: [
    {
      name: "GitHub",
      url: "https://github.com/KevinTamCS",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/kevintamcs/",
    },
    {
      name: "Email",
      url: "mailto:contact@kevintamcs.com",
    },
    {
      name: "Resume",
      url: "/resume",
    },
  ],

  navLinks: {
    menu: [
      {
        name: "About",
        url: "#about",
      },
      {
        name: "Projects",
        url: "#projects",
      },
      {
        name: "Resume",
        url: "/resume",
      },
    ],
    button: {
      name: "Contact",
      url: "mailto:contact@kevintamcs.com",
    },
  },

  footerLinks: [
    {
      name: "Home",
      url: "/",
    },
  ],
}
