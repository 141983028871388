import React from "react"
import PropTypes from "prop-types"

const Email = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      fill={color}
      height="24"
      width="24"
      viewBox="0 0 24 24"
      aria-label="Email"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z" />
    </svg>
  )
}

Email.propTypes = {
  color: PropTypes.string,
}

Email.defaultProps = {
  color: "#000000",
}

export default Email
